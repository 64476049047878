export const imageObject = {
    image: require("../images/content-img-1.png"),   

    image1: require("../images/content-img-2.png"),
    image2: require("../images/content-img-3.png"),
    image3: require("../images/iphone-img.png.png"),
    image4: require("../images/tt3.jpg"),
    image5: require("../images/tt1.jpg"),
    image6: require("../images/tt2.jpg"),
    image7: require("../images/tt3.jpg"),   
    image8: require("../images/tt4.jpg"),  
    image9: require("../images/tt5.jpg"),   
     image10: require("../images/tt6.jpg"),

     image11: require("../images/tt7.jpg"),

    twitter: require("../images/twitter.png"),
    facebook: require("../images/facebook.png"),
    instagram: require("../images/instagram.png"),
    youtube: require("../images/youtube.png"),
    tikTok: require("../images/tiktok.png"),
    threads : require("../images/threads.png"),
        telegram : require("../images/telegram.png"),



};