import React from 'react';
import { useInView } from 'react-intersection-observer';
import styles from './Contentright.module.css'
import colors from '../constants/colors.json'
import app_store from "../images/app-store.png";
import google_play from "../images/google-play.png";
import styles2 from "./Heroarea.module.css";

export default function AboutUs({title, description,}) {
  
  const { ref, inView } = useInView({
    triggerOnce: true, 
  });
  const updatedDescription = description
  ? description.replace(/rgb\(0,\s*0,\s*0\)/g, 'white')
  : '';  console.log(updatedDescription)
  return (
    
              <div className="row justify-content-center" style={{ backgroundColor: colors.primary }} >
      <div className="col-xl-9" >
      <div className="row align-items-center justify-content-center justify-content-lg-start">
                    
                    <div>
                      {/* <br /> */}
                      {/* <br /> */}

                      <div className={`${styles.contentrighttext} ${styles.contentWrapper} ${inView ? styles.inView : ''}`}ref={ref} >
                        {/* <h2 style={{color: colors.white , textAlign: 'left'}}>{title}</h2> */}
                        <p >
                        <div    style={{color: colors.white}}
                            dangerouslySetInnerHTML={{
                              __html: updatedDescription,
                            }}
                          />
                          
                        </p>
                      </div>
                    </div>
                   
                  </div>
                </div>
              </div>
          
         
  );
}
