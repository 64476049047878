import React, { useState } from "react";
import styles from "./Faq.module.css"; // Adjust based on your file structure
import color from "../constants/colors.json"; // Adjust based on your file structure

export default function Faq({ faqheading, faqContent, isRtl }) {
  // State for tracking open FAQ items
  const [openItems, setOpenItems] = useState([]);

  // Parse FAQ content (Q: and A: sections)
  const parseFAQContent = (rawHTML) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(rawHTML, 'text/html');

    // Extract questions and answers
    const faqArray = [];
    let currentQuestion = null;

    doc.body.childNodes.forEach((node) => {
      if (node.tagName === 'P' && node.textContent.trim() === '') {
        // Skip <p><br></p> nodes
        return;
      }
      if ((node.tagName === 'P'||node.tagName === 'H1'||node.tagName === 'H2'||node.tagName === 'H3') && node.textContent.trim().startsWith('Q:')) {
        if (currentQuestion) faqArray.push(currentQuestion); // Push previous Q&A pair
        currentQuestion = { question: node.textContent.trim().replace(/^Q:\s+/, ''), answer: '', style: node.getAttribute('style') || '' };
      } else if (currentQuestion) {
        // Append to the current answer
        if (node.tagName === 'P'||node.tagName === 'H1'||node.tagName === 'H2'||node.tagName === 'H3' || node.tagName === 'OL') {
          currentQuestion.answer += node.outerHTML.trim().replace(/^A:\s+/i, '');;
        }
      }
    });

    if (currentQuestion) faqArray.push(currentQuestion);
    return faqArray;
  };

  // Parse the FAQ content to extract questions and answers
  const faqData = parseFAQContent(faqContent);

  const toggleItem = (index) => {
    setOpenItems((prev) =>
      prev.includes(index) ? prev.filter((item) => item !== index) : [...prev, index]
    );
  };

  return (
    <div className="">
      <section className={styles.faqContainer}>
        <h1 className={styles.faqHeading}>{faqheading}</h1>
        {faqData.map((item, index) => (
          <div
            key={index}
            className={styles.faqItem}
            style={{
              background: color.accent,
            }}
            onClick={() => toggleItem(index)}
          >
            {/* <h4 className={styles.faqQuestion}>{item.question}</h4> */}
            <div
                  dangerouslySetInnerHTML={{
                    __html: item.question,
                  }}
                />
            {openItems.includes(index) && (
              <div className={styles.faqBody}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: item.answer,
                  }}
                />
              </div>
            )}
          </div>
        ))}
      </section>
    </div>
  );
}
