import React from "react";
import favicon from "../images/favicon.jpg";
import styles from "./Footer.module.css";
import { Link } from "react-router-dom";
export default function Footer({
  about,
  features,
  policy,
  contact,
  reserved,
  image,
}) {
  return (
    <footer className={styles.footermain}>
      <hr></hr>
      <div className="container">
        <div className={styles.footer}>
          <div>
            <div>
              <a href="#">
                <img
                  src={image}
                  width="50px"
                  alt="logo"
                  style={{ borderRadius: "10px" }}
                />
              </a>
            </div>
          </div>
          {/* <div className="col-md-6">
            <div className={styles}>
              <ul className={styles.footeritems}>
                <li>
                  <a href="#about">{about}</a>
                </li>
                <li>
                  <a href="#feature">{features}</a>
                </li>
                <li>
                  <Link to="/privacy-policy">{policy}</Link>
                </li>
                <li>
                  <a href="mailto:infotargetube@gmail.com">{contact}</a>
                </li>
                <form
                  action="https://www.paypal.com/donate"
                  method="post"
                  target="_top"
                >
                  <input
                    type="hidden"
                    name="hosted_button_id"
                    value="4HZEHY4DQQWJY"
                  />
                  <input
                    type="image"
                    src="https://www.paypalobjects.com/en_US/i/btn/btn_donate_LG.gif"
                    border="0"
                    name="submit"
                    title="PayPal - The safer, easier way to pay online!"
                    alt="Donate with PayPal button"
                  />
                  <img
                    alt=""
                    border="0"
                    src="https://www.paypal.com/en_US/i/scr/pixel.gif"
                    width="1"
                    height="1"
                  />
                </form>
              </ul>
            </div>
          </div> */}
         <div className="col-md-3" >
  
    {/* <p style={{alignItems: "center",justifyContent: "center", textAlign : "center" , alignContent: "center"}}>{reserved}</p> */}
    <div
                  dangerouslySetInnerHTML={{
                    __html: reserved,
                  }}
                />
          </div>
        </div>
      </div>
    </footer>
  );
}
